import { useEffect } from "react";
import { Appearance } from "react-native";
import { useSelector } from "react-redux";
import { RootState } from "../store";
import useColorScheme from "./useColorScheme";

export default function useTheme() {
  const userTheme: string = useSelector((state: RootState) => state.user.theme);

  const theme = () => {
    switch (userTheme) {
      case "dark":
        return "dark";
      case "light":
        return "light";
      default:
        return Appearance.getColorScheme();
    }
  };
  return theme();
}
