import * as React from 'react';
import { SafeAreaView, TouchableOpacity, View } from 'react-native';
import Header from '../components/Header';
import FeedList from '../components/FeedList';
import { formatTweetDate } from '../utils/TweetUtils';
import { Text } from '../components/Themed';
import Fonts from '../constants/Fonts';
import { ScreenStyles } from '../styles/AppStyles';
import { auth } from '../config/firebase';
import { signOut } from "firebase/auth";

type DashboardState = {
  date:string,
}

export default class Dashboard extends React.Component<{}, DashboardState> {
  constructor(props:any){
    super(props)
    this.state = {
      date: new Date().toLocaleDateString(),
    }
    
    this.onViewableItemsChanged = this.onViewableItemsChanged.bind(this)
  }

  onViewableItemsChanged = ({viewableItems, changed}:any): void => {
    if(viewableItems[0] && viewableItems[0].item.createdAt){
      this.setState({ date: formatTweetDate(viewableItems[0].item.createdAt)})
    }
  };

  render() {
    return (
      <SafeAreaView testID="MainFeed" style={{ alignItems: 'center', flex: 1, overflow: 'hidden' }}>
        <Header screenTitle="Gabba" />
        <View style={[ScreenStyles.containerCard, {alignItems: 'flex-end'}]}>
          <Text style={[Fonts.h3, { paddingBottom: 8}]} key={this.state.date}>{this.state.date}</Text>
        </View>
        <FeedList onViewableItemsChanged={this.onViewableItemsChanged} />
      </SafeAreaView>
    );
  }
}