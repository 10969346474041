import { UserInfo as FireBaseUserInfo } from '@firebase/auth-types'
export const SET_USER_THEME = "SET_USER_THEME";
export const SET_USER_INFO = "SET_USER_INFO";


export interface UserTheme {
  theme: "dark" | "light" | "system";
}

export interface UserInfo {
  userInfo: FireBaseUserInfo;
}

export interface SetUserTheme {
  type: typeof SET_USER_THEME;
  payload: UserTheme;
}

export interface SetUserInfo {
  type: typeof SET_USER_INFO;
  payload: UserInfo;
}


export type UserAction = SetUserTheme;
