import React from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import { GabbaStackParams, RootDrawerParamList } from '../types/Navigation';
import AccountScreen from '../screens/AccountScreen';
import LoginScreen from '../screens/LoginScreen';
import SignUpScreen from '../screens/SignUpScreen';
import { getAuth } from 'firebase/auth';


declare global {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace ReactNavigation {
    interface RootParamList extends RootDrawerParamList { }
  }
}

const Stack = createStackNavigator<GabbaStackParams>();

export default function AccountNavigator() {
  return (
    <Stack.Navigator
      initialRouteName='Overview'
      screenOptions={{ headerShown: false, animationEnabled: false }}>
      <Stack.Screen name="Overview" component={AccountScreen} />
    </Stack.Navigator>
  );
}
