import * as React from 'react';
import { SafeAreaView, TouchableOpacity, View } from 'react-native';
import Header from '../components/Header';
import Fonts from '../constants/Fonts';
import { Container, Text } from 'native-base';
import ThemeToggle from '../components/ThemeToggle';
import { getAuth, signOut, Auth } from 'firebase/auth';
import { auth } from '../config/firebase';
import { connect } from 'react-redux';
import { RootState, Store } from '../store';
import { AuthState } from '../store/types/Auth';
import { logoutUser } from '../store/actions/AuthActions';


type AccountScreenProps = {
  auth: AuthState,
  navigation: any,
}

class Account extends React.Component<AccountScreenProps> {
  constructor(props:any){
    super(props)
  }

  handleSignOut = () => {
    Store.dispatch(logoutUser())
    this.props.navigation.navigate("Dashboard")
  }

  LoggedInLinks = () => {
    if(this.props.auth.signedIn) {
      return (
        <TouchableOpacity style={[{ padding: 16,  }]} onPress={() => this.handleSignOut()}>
          <Text style={[{ textAlign: 'left'}, Fonts.p]}>Log Out</Text>
        </TouchableOpacity>
      );
    }
    return (
      <View>
        <TouchableOpacity style={[{ padding: 16,  }]} onPress={() => this.props.navigation.navigate('SignUp')}>
          <Text style={[{ textAlign: 'left' }, Fonts.p]}>Start Free Trial</Text>
          </TouchableOpacity>
          <TouchableOpacity style={[{ padding: 16,  }]} onPress={() => this.props.navigation.navigate('Login')}>
          <Text style={[{ textAlign: 'left'}, Fonts.p]}>Login</Text>
          </TouchableOpacity>
      </View>
    )

  }

  render() {
    return (
      <SafeAreaView testID="Account" style={{ alignItems: 'center', flex: 1, overflow: 'hidden' }}>
        <Header screenTitle="Account" />
        <Container width={'100%'} maxWidth={'100%'} style={{flex: 1, maxWidth: 1100 }}>
          <this.LoggedInLinks />
          <ThemeToggle/>
        </Container>
      </SafeAreaView>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(Account);