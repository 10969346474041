import { AxiosResponse } from 'axios';
import HttpClient from './HttpClient';
import { auth } from "../../config/firebase";
import { signInWithEmailAndPassword } from "firebase/auth";


export class AuthApi extends HttpClient {
  public authorizeUser = async (
    dispatch:any, email: string, pass: string, setLoginRequest:any, setLoginSuccess:any, setLoginError:any,
  ): Promise<AxiosResponse> => {
    dispatch(setLoginRequest());
    await signInWithEmailAndPassword(auth, email, pass).then((UserCredential) => {
        dispatch(setLoginSuccess(UserCredential));
      })
      .catch((response) => {
        dispatch(setLoginError(response));
      });
  };
  logoutUser = async (dispatch:any, setSignOut:any) => {
    dispatch(setSignOut());
    auth.signOut()
  }
  registerUser = async (dispatch:any, userData:any, setLoginRequest:any, setLoginSuccess:any, setLoginError:any) => {
    dispatch(setLoginRequest());
    await this.instance
      .post("/api/auth/signup", userData)
      .then(({data}) => {
        dispatch(setLoginSuccess(data));
      })
      .catch(err =>
        dispatch(setLoginError(err))
      );
  }
}
