export const formatTweetText = (string: string) => {
    return string.replace(/(?:https?|ftp):\/\/[\n\S]+/g, '').replace(/\*/g, '').toUpperCase()
}

export const formatTweetTime = (createdAt: string) => {
    return new Date(createdAt).toLocaleTimeString();
}

export const formatTweetDate = (createdAt: string) => {
    return new Date(createdAt).toLocaleDateString();
}

export const tweetExtractorKey = (tweet: Object, index: number) => {
    return index.toString();
};